import brandCache from '@/cache/brand.cache.js';

export const modelStore = {
	state: {
		name: 'model',
		title: 'Model',
		titlePlural: 'Models',
		nav: {
			base: 'model',
			newView: true,
			views: [
				{ view: 'details', label: 'Details', }
			]
		},
		search: {
			endPoint: 'models',
			formFields: {
				unitModelNum: {
					val: '',
					qsField: '[unit_model_num]',
					label: 'Model',
					component: 'SearchTextInput'
				},
				cdiPartID: {
					val: '',
					qsField: '[cdi_part_id]',
					label: 'Part',
					cdiType: 'part',
					component: 'SearchTypeaheadInput'
				},
				brandID: {
					val: 0,
					qsField: '[brand_id]',
					label: 'Brand',
					component: 'SearchSelectInput',
					options: brandCache.BRANDS
				},
				enabledOnly: {
					val: 1,
					qsField: '[enabled_only]',
					label: 'Enabled Only',
					component: 'SearchCheckboxInput'
				},
				searchIndex: {val: 0, qsField: '[search_index]'}
			},
			resultFields: {
				unit_model_id: {
					header: 'Unit Model #',
					link: 'model'
				},
				unit_model_num: {
					header: 'Model',
					link: ''
				},
				cdi_part_id: {
					header: 'Part',
					textField: 'cdi_part_num',
					link: 'part'
				},
				cdi_file_id: {
					header: '2d',
					textField: 'cdi_file_datetime',
					link: 'cdi_file'
				}
			}
		},
		object: {
			fields: [
				{
					name: 'unit_model_id',
					label: 'Unit Model ID',
					component: 'DetailsStatic'
				},
				{
					name: 'unit_model_num',
					label: 'Model',
					component: 'DetailsText',
				},
				{
					name: 'cdi_part_id',
					label: 'Part',
					cdiType: 'part',
					displayField: 'cdi_part_num',
					component: 'DetailsTypeahead'
				},
				{
					name: 'disable',
					label: 'Disable',
					component: 'DetailsCheckbox'
				},
				{
					name: 'old_product_model',
					label: 'Old Model',
					component: 'DetailsCheckbox'
				}
			]
		}
	}
}