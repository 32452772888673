<template>
    <div>
        <h1>New Model</h1>
        <form @submit.prevent="newModel">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Model</label>
                    <input type="text" v-model="formFields.unit_model_num.val" class="form-control" placeholder="New Unit">
                </div>
            </div>
            <input type="submit" class="btn btn-default" value="Save">
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js'
    import helpers from "@/store/helpers";
    export default {
        name: "New",
        data() {
            return {
                state: store.state,
                formFields: {
                    unit_model_num: { val: '', qsField: '[unit_model_num]' }
                }
            }
        },
        created() {
            store.clearObjectData();
        },
        methods: {
            newModel: function() {
                store.api('save_model', helpers.getQSPairs(this.formFields))
                .then(result => {
                        this.$router.push({path: `/${this.state.nav.base}/${result.unit_model_id}`});
                    }
                );
            }
        },
    }
</script>

<style scoped>

</style>